import * as Styled from './OxPanelViewTreatmentPlans.styled';

import React, { useContext, useEffect, useState } from 'react';
import { TClinic, TTreatmentConsent, TTreatmentPlan } from 'src/services/api/api.types';
import { DateTime } from 'luxon';
import { OxSignature } from 'src/components/OxSignature';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import {
    getTreatmentConsent,
    getTreatmentPlan,
    postTreatmentConsent
} from 'src/services/api/treatmentplan';
import { ERoutes } from 'src/config/enums';
import { navigate } from 'gatsby';
import { numberSuffix } from 'src/utils/numberSuffix';
import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import { getUrl } from 'src/services/api/api.core';
import { getPatientTreatmentplan } from 'src/services/api/patient';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import getSymbolFromCurrency from 'currency-symbol-map';
import { useStore } from 'react-redux';
import { getPatientProfile } from 'src/services/api/patientProfile';
import isClinicIdDubai from 'src/services/clinic/isClinicIdDubai';
import { getClinicById } from 'src/services/api';
import DOMPurify from 'dompurify';

type TProps = {
    clinicId?: number;
    patientId?: string;
    allowConsent?: boolean;
    showConsentDate?: boolean;
    isPatient?: boolean;
};

export const OxPanelViewTreatmentPlans = ({
    clinicId,
    patientId,
    allowConsent,
    showConsentDate,
    isPatient
}: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const store = useStore();

    const { showAlert } = useContext(AlertContext);
    const [clinic, setClinic] = useState<TClinic>();
    const [treatmentPlans, setTreatmentPlans] = useState<TTreatmentPlan[]>([]);
    const [downloadUrl, setDownloadUrl] = useState<string>('');
    const [consent, setConsent] = useState<TTreatmentConsent[]>([]);
    const latestConsent: TTreatmentConsent | null =
        consent?.reduce((a, b) => (a.updatedAt > b.updatedAt ? a : b), {} as TTreatmentConsent) ??
        null;

    const latestPlan =
        treatmentPlans?.reduce(
            (a, b) => (a.updatedAt > b.updatedAt ? a : b),
            {} as TTreatmentPlan
        ) ?? null;
    const areAllPlansConsented = (latestPlan?.updatedAt ?? '') < (latestConsent?.updatedAt ?? '');

    const getClinicData = async (): Promise<void> => {
        const fetchData = await getClinicById({ clinicId: '' });

        if (fetchData) {
            const clinincs = fetchData.reduce((total, current) => {
                total[current.id] = current;
                return total;
            }, {});
            setClinic(clinincs);
        }
    };

    const submit = (signature: string): void => {
        patientId &&
            postTreatmentConsent({ patientId, imageData: signature })
                .then((data) => validateResponse(data))
                .then(() => {
                    navigate(ERoutes.PanelStaffPin, {
                        state: {
                            successUrl: ERoutes.PanelStaffDashboard
                        }
                    });
                })
                .catch((e) => {
                    showAlert({
                        type: EAlertVariant.Error,
                        header: 'Error',
                        title: 'An error occurred when trying to post consent, if the problem persists please contact us.'
                    });
                    console.error(e);
                });
    };

    const handleBackButtonClick = (): void => {
        navigate(-1);
    };

    const createdDate = DateTime.fromISO(treatmentPlans.length ? treatmentPlans[0].createdAt : '');

    useEffect(() => {
        let unmounted = false;
        const asyncInit = async (): Promise<void> => {
            getClinicData();
            if (isPatient) {
                getPatientTreatmentplan()
                    .then(validateResponse)
                    .then((plans) =>
                        plans.filter((plan: TTreatmentPlan) => plan.status !== 'archived')
                    )
                    .then((data) => !unmounted && setTreatmentPlans(data))
                    .catch((e) => {
                        showAlert({
                            type: EAlertVariant.Error,
                            header: 'Error',
                            title: 'An error occurred when trying to fetch treatment plan data, if the problem persists please contact us.'
                        });
                        console.error(e);
                    });

                const { user } = store.getState();
            } else {
                getTreatmentPlan({ patientId: patientId ?? '' })
                    .then(validateResponse)
                    .then((data) => {
                        if (!unmounted) {
                            let updatedData;
                            updatedData = data.plans.filter(
                                (plan: TTreatmentPlan) => plan.clinicId == clinicId
                            ) as TTreatmentPlan[];
                            updatedData = updatedData.filter(
                                (plan: TTreatmentPlan) => plan.status !== 'archived'
                            ) as TTreatmentPlan[];

                            setTreatmentPlans(updatedData);
                            setDownloadUrl(data.downloadUrl as string);
                        }
                    })
                    .catch((e) => {
                        showAlert({
                            type: EAlertVariant.Error,
                            header: 'Error',
                            title: 'An error occurred when trying to fetch treatment plan data, if the problem persists please contact us.'
                        });
                        console.error(e);
                    });

                getPatientProfile({ patientId: patientId as string })
                    .then(validateResponse)
                    .then((data) => !unmounted)
                    .catch(console.error);
            }

            allowConsent &&
                patientId &&
                getTreatmentConsent({ patientId })
                    .then((data) => validateResponse(data))
                    .then((data) => !unmounted && setConsent(data))
                    .catch((e) => {
                        showAlert({
                            type: EAlertVariant.Error,
                            header: 'Error',
                            title: 'An error occurred when trying to fetch consent, if the problem persists please contact us.'
                        });
                        console.error(e);
                    });
        };

        asyncInit();
        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <Styled.Container>
            <Styled.Header isPatient={isPatient}>
                {!isPatient && <Styled.BackButton onClick={handleBackButtonClick} />}
                Treatment Plan
                {downloadUrl && (
                    <Styled.DownloadButton type="button" asAnchor to={downloadUrl}>
                        Download
                    </Styled.DownloadButton>
                )}
            </Styled.Header>
            {treatmentPlans.length > 0 && (
                <>
                    <Styled.Section>
                        <Styled.SectionHeader>Details</Styled.SectionHeader>

                        <Styled.Details isLast={true}>
                            <Styled.DetailLine>
                                <Styled.DetailLabel isPatient={isPatient}>
                                    Patient
                                </Styled.DetailLabel>
                                <Styled.DetailValue>
                                    {treatmentPlans[0].patientName}
                                </Styled.DetailValue>
                            </Styled.DetailLine>

                            <Styled.DetailLine>
                                <Styled.DetailLabel isPatient={isPatient}>
                                    Doctor
                                </Styled.DetailLabel>
                                <Styled.DetailValue>
                                    {treatmentPlans[0].practitionerName}
                                </Styled.DetailValue>
                            </Styled.DetailLine>

                            <Styled.DetailLine>
                                <Styled.DetailLabel isPatient={isPatient}>
                                    Created
                                </Styled.DetailLabel>
                                <Styled.DetailValue>
                                    {`${createdDate.toFormat('d')}${numberSuffix(
                                        createdDate.day
                                    )} ${createdDate.toFormat('MMMM yyyy')}`}
                                </Styled.DetailValue>
                            </Styled.DetailLine>
                        </Styled.Details>
                    </Styled.Section>
                    <Styled.Section>
                        <Styled.SectionHeader>Treatments</Styled.SectionHeader>
                        {treatmentPlans?.map((plan: TTreatmentPlan, goalKey: number | string) => (
                            <Styled.Details
                                key={goalKey}
                                isLast={
                                    goalKey === parseInt(Object.keys(treatmentPlans).pop() ?? '-1')
                                }
                            >
                                <Styled.DetailLine>
                                    <Styled.DetailLabel isPatient={isPatient}>
                                        Goal
                                    </Styled.DetailLabel>
                                    <Styled.DetailValue>{plan.goal.title}</Styled.DetailValue>
                                    {plan.goal.description && (
                                        <Styled.DetailDescription>
                                            {plan.goal.description}
                                        </Styled.DetailDescription>
                                    )}
                                </Styled.DetailLine>

                                <Styled.DetailLine>
                                    <Styled.DetailLabel isPatient={isPatient}>
                                        Outcome
                                    </Styled.DetailLabel>
                                    <Styled.DetailValue>
                                        {plan?.planOutcome?.outcome?.title}
                                    </Styled.DetailValue>
                                    {plan?.planOutcome?.outcome?.clinicDescription?.[
                                        plan.clinicId
                                    ] && (
                                        <Styled.DetailDescription>
                                            {
                                                plan?.planOutcome?.outcome?.clinicDescription?.[
                                                    plan.clinicId
                                                ]
                                            }
                                        </Styled.DetailDescription>
                                    )}
                                    {!plan?.planOutcome?.outcome?.clinicDescription?.[
                                        plan.clinicId
                                    ] &&
                                        plan?.planOutcome?.outcome?.description && (
                                            <Styled.DetailDescription>
                                                {plan?.planOutcome?.outcome?.description}
                                            </Styled.DetailDescription>
                                        )}
                                </Styled.DetailLine>

                                {!!plan.comment && (
                                    <Styled.DetailLine>
                                        <Styled.DetailLabel isPatient={isPatient}>
                                            Doctor&apos;s Notes
                                        </Styled.DetailLabel>
                                        <Styled.DetailDescription
                                            asValue
                                            dangerouslySetInnerHTML={{
                                                __html: DOMPurify.sanitize(plan?.comment)
                                            }}
                                        ></Styled.DetailDescription>
                                    </Styled.DetailLine>
                                )}

                                <Styled.DetailLine>
                                    <Styled.DetailLabel isPatient={isPatient}>
                                        Treatment Cost
                                    </Styled.DetailLabel>
                                    <Styled.DetailValue>
                                        {plan?.planOutcome?.outcome?.clinicIsFromPrice[
                                            plan.clinicId
                                        ] ? (
                                            <>From: </>
                                        ) : (
                                            <></>
                                        )}
                                        {getSymbolFromCurrency(
                                            clinic?.[plan.clinicId]?.currency ?? ''
                                        )}
                                        {plan?.price?.toLocaleString()}
                                    </Styled.DetailValue>
                                </Styled.DetailLine>

                                <Styled.DetailLine>
                                    <Styled.DetailLabel isPatient={isPatient}>
                                        Treatment Location
                                    </Styled.DetailLabel>
                                    <Styled.DetailValue>
                                        {clinic?.[plan.clinicId]?.displayName}
                                    </Styled.DetailValue>
                                </Styled.DetailLine>

                                {!!plan.appointmentDate && (
                                    <Styled.DetailLine>
                                        <Styled.DetailLabel isPatient={isPatient}>
                                            Expected Appointment
                                        </Styled.DetailLabel>
                                        <Styled.DetailValue>
                                            {DateTime.fromISO(plan.appointmentDate).toFormat(
                                                'LLLL yyyy'
                                            )}
                                        </Styled.DetailValue>
                                    </Styled.DetailLine>
                                )}

                                {!!showConsentDate && consent.length > 0 && (
                                    <Styled.DetailLine>
                                        <Styled.DetailLabel isPatient={isPatient}>
                                            Consent Date
                                        </Styled.DetailLabel>
                                        <Styled.DetailValue>
                                            {DateTime.fromISO(consent[0].createdAt ?? '').toFormat(
                                                'dd LLLL yyyy'
                                            )}
                                        </Styled.DetailValue>
                                    </Styled.DetailLine>
                                )}
                                {!isClinicIdDubai(clinic?.id ?? 0) && (
                                    <Styled.DetailLine>
                                        <Styled.DetailLabel isPatient={isPatient}>
                                            Medical Treatment
                                        </Styled.DetailLabel>
                                        <Styled.DetailValue>
                                            {plan.isMedical ? 'Yes' : 'No'}
                                        </Styled.DetailValue>
                                    </Styled.DetailLine>
                                )}
                            </Styled.Details>
                        ))}
                    </Styled.Section>
                    {!!allowConsent && (
                        <Styled.SignatureContainer>
                            <Styled.SectionHeader>Consent</Styled.SectionHeader>
                            {!areAllPlansConsented ? (
                                <>
                                    <Styled.SignatureCopy>
                                        Please sign in the box below to confirm your consent to the
                                        above treatment plan.
                                    </Styled.SignatureCopy>
                                    <OxThemedInput theme={EInputTheme.BackgroundGold}>
                                        <OxSignature
                                            captureTriggerTitle="Consent to Plan"
                                            onCapture={submit}
                                        />
                                    </OxThemedInput>
                                </>
                            ) : (
                                <Styled.SignatureImage src={getUrl(latestConsent?.path)} />
                            )}
                        </Styled.SignatureContainer>
                    )}
                </>
            )}
        </Styled.Container>
    );
};
