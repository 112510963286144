import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from 'react';
import * as Styled from './OxStaffDashboardPatientExtras.styled';

import { clinikoPatientUrl } from 'src/services/api';
import { ERoles, ERoutes } from 'src/config/enums';
import { EInputTheme, OxThemedInput } from 'src/components/OxThemedInput';
import { OxButton } from 'src/components/OxButton';
import {
    TClinic,
    TMakeup,
    TPractitioner,
    TPsychologicalAttributes,
    //TRefreshments,
    TTreatmentAestheticianNote,
    TTreatmentNote,
    TTreatmentPlan
} from 'src/services/api/api.types';
import {
    getPractitioner,
    getPractitionerPsychologicalAttributes
} from 'src/services/api/practitioner';
import {
    getShareConsent,
    getTreatmentPlan,
    transferTreatmentPlan
} from 'src/services/api/treatmentplan';
import { getMakeup, getRefreshments } from 'src/services/api/reception';
import { useStore } from 'react-redux';
import { applyValues } from 'src/utils/applyValues';
import { getTreatmentAesthetician, getTreatmentNotes } from 'src/services/api/treatment';
import {
    getTreatmentButtonMessage,
    shouldHideAestheticianNotesButton,
    shouldHideTreatmentNotesButton
} from 'src/utils/treatmentNoteUtils';
import { WebsiteDataContext } from 'src/context/WebsiteDataContext';
import { TAppointmentExtras } from 'src/panel/components/OxPanelStaffDashboard/components/OxStaffDashboardAppointments/components/OxPatientsSliderItem';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { navigate } from '@reach/router';

type TLink = {
    title: string;
    hide?: boolean;
    to?: string;
    state?: object;
    newWindow?: boolean;
    onClick?: () => void;
};

export const OxStaffDashboardPatientExtras: React.FC<TAppointmentExtras> = ({
    patientId,
    patientName,
    patientProfile,
    appointment
}: TAppointmentExtras) => {
    const { validateResponse } = useValidateResponse();
    const websiteDataContext = useContext(WebsiteDataContext);

    const isPatientProfile = !!patientProfile;
    const store = useStore();
    const { user } = store.getState();
    const { roles } = user ?? { roles: [] };

    const [psychologicalAttributes, setPsychologicalAttributes] = useState<
        TPsychologicalAttributes[]
    >([]);
    const [practitioner, setPractitioner] = useState<TPractitioner | null>();
    const [treatmentPlans, setTreatmentPlan] = useState<TTreatmentPlan[]>(
        patientProfile ? patientProfile.plans : []
    );

    const [treatmentAesthetician, setTreatmentAesthetician] = useState<
        TTreatmentAestheticianNote[]
    >([]);
    const [makeup, setMakeup] = useState<TMakeup | null>(
        patientProfile ? { makeup: patientProfile.makeup } : null
    );
    // const [refreshments, setRefreshments] = useState<TRefreshments | null>(
    //     patientProfile ? { refreshments: patientProfile.refreshments } : null
    // );
    const activeTreatmentPlans = useMemo(() => {
        return (treatmentPlans ?? []).filter((plan) => plan.status !== 'archived');
    }, [treatmentPlans]);

    const [treatmentNotes, setTreatmentNotes] = useState<TTreatmentNote[]>([]);
    const [shareConsent, setShareConsent] = useState<TTreatmentNote>();

    let appointmentTreatmentNote = '';
    if (treatmentNotes.length > 0) {
        appointmentTreatmentNote = treatmentNotes?.find(
            (note) => note.appointmentId === appointment?.id
        );
    }
    const clinicId = appointment?.clinicId ?? user?.clinicId;
    const clinic = websiteDataContext.locations?.find((item) => item.id === clinicId);
    const doctors = websiteDataContext.doctors;
    const clinicCurrency = clinic?.currency;

    const createEditTreatmentPlanState = {
        patientId: patientId,
        patientName: patientName,
        activeTreatmentPlans,
        clinicCurrency
    };

    const treatmentPlansPractitionerId: number = activeTreatmentPlans[0]?.practitionerId ?? 0;

    const links: TLink[] = [
        {
            to: clinikoPatientUrl({
                patientId: patientId ?? '',
                clinicId: clinic?.id ?? 0
            }),
            title: 'Cliniko Profile',
            newWindow: true,
            hide:
                roles?.includes(ERoles.Barista) ||
                (roles?.includes(ERoles.MakeupArtist) && !roles?.includes(ERoles.Receptionist))
        },
        {
            to: applyValues(ERoutes.PanelStaffDashboardPsychologicalAttributes, {
                patientId
            }),
            title: 'Goals/Fears',
            state: { psychologicalAttributes: psychologicalAttributes },
            newWindow: true,
            hide:
                (psychologicalAttributes?.length ?? 0) === 0 ||
                !roles?.includes(ERoles.Practitioner)
        },
        // {
        //   to: ERoutes.PanelStaffDashboardRefreshments,
        //   title: "Refreshments",
        //   state: {
        //     patientId,
        //     patientName,
        //   },
        //   hide: !roles?.includes(ERoles.Receptionist),
        // },
        {
            to: applyValues(ERoutes.PanelStaffDashboardPatientById, { patientId }),
            title: 'Patient Profile',
            hide:
                isPatientProfile ||
                (!roles.includes(ERoles.Practitioner) &&
                    !roles.includes(ERoles.Nurse) &&
                    !roles?.includes(ERoles.SkincareConsultant) &&
                    !roles.includes(ERoles.Receptionist))
        },
        {
            to: ERoutes.PanelStaffDashboardTreatmentNotes,
            title: getTreatmentButtonMessage({
                roles: roles,
                treatmentNote: appointmentTreatmentNote
            }),
            state: {
                appointmentId: !isPatientProfile ? appointment?.id : undefined,
                patientId: isPatientProfile ? patientId : undefined,
                clinic,
                clinicId
            },
            hide:
                shouldHideTreatmentNotesButton(
                    roles,
                    clinic ?? ({} as TClinic),
                    patientId,
                    appointment,
                    appointmentTreatmentNote,
                    null,
                    isPatientProfile,
                    doctors,
                    practitioner
                ) ||
                !getTreatmentButtonMessage({
                    roles: roles,
                    treatmentNote: appointmentTreatmentNote
                })
        },
        {
            to: applyValues(ERoutes.PanelStaffDashboardViewTreatmentPlans, {
                patientId
            }),
            title: 'View/Consent Treat. P.',
            state: {
                allowConsent: roles?.includes(ERoles.Practitioner),
                showConsentDate: true
            },
            hide:
                (treatmentPlans?.length ?? 0) === 0 ||
                roles?.includes(ERoles.Barista) ||
                (roles?.includes(ERoles.MakeupArtist) && !roles?.includes(ERoles.Receptionist))
        },
        {
            to: ERoutes.PanelStaffDashboardCreateEditTreatmentPlan,
            title: `Create${(activeTreatmentPlans?.length ?? 0) > 0 ? '/Edit' : ''} Treat. P.`,
            hide:
                !roles?.includes(ERoles.Practitioner) ||
                (roles?.includes(ERoles.Practitioner) &&
                    treatmentPlansPractitionerId !== user.id &&
                    activeTreatmentPlans.length > 0),
            state: createEditTreatmentPlanState
        },
        {
            to: applyValues(ERoutes.PanelStaffDashboardViewAestheticianTreatment, {
                patientId
            }),
            title:
                (treatmentAesthetician?.length ?? 0) === 0
                    ? 'Enter Aesthetician Notes'
                    : 'View/Edit Aesthetician Notes',
            hide:
                (!roles?.includes(ERoles.SkincareConsultant) &&
                    !roles?.includes(ERoles.Practitioner) &&
                    !roles?.includes(ERoles.Nurse)) ||
                shouldHideAestheticianNotesButton(appointment, doctors),
            state: {
                appointmentId: appointment?.id,
                readOnly: false
            }
        },
        {
            to: applyValues(ERoutes.PanelStaffDashboardViewAestheticianTreatment, {
                patientId
            }),
            title: 'View Aesthetician Notes',
            hide:
                (treatmentAesthetician?.length ?? 0) === 0 ||
                !roles?.includes(ERoles.Receptionist) ||
                shouldHideAestheticianNotesButton(appointment, doctors),
            state: {
                appointmentId: appointment?.id,
                readOnly: true
            }
        },
        {
            to: applyValues(ERoutes.PanelStaffDashboardShareConsent, {
                patientId
            }),
            title: shareConsent ? 'View Sharing Consent (consent given)' : 'Consent Sharing Notes',
            state: {
                allowConsent: roles?.includes(ERoles.Practitioner) || roles?.includes(ERoles.Nurse)
            },
            hide: !roles?.includes(ERoles.Practitioner) && !roles?.includes(ERoles.Nurse)
        },
        {
            onClick: (): void => {
                transferTreatmentPlan({ patientId })
                    .then(validateResponse)
                    .then(() =>
                        navigate(ERoutes.PanelStaffDashboardCreateEditTreatmentPlan, {
                            state: createEditTreatmentPlanState
                        })
                    )
                    .catch(console.error);
            },
            title: `Transfer Treatment Plans`,
            hide:
                !roles?.includes(ERoles.Practitioner) ||
                activeTreatmentPlans.length <= 0 ||
                treatmentPlansPractitionerId === user.id
        },
        {
            to: ERoutes.PanelWelcomeInternal,
            title: `Client Onboarding`,
            hide: !isPatientProfile || !roles?.includes(ERoles.Receptionist),
            state: {
                patientId
            }
        },
        {
            to: ERoutes.PanelStaffDashboardTreatmentNotes,
            title: 'Last Treatment Note',
            state: {
                preSetTreatmentNotes: [treatmentNotes[treatmentNotes.length - 1]],
                clinicId,
                patientId,
                appointmentId: appointment?.id
            },
            newWindow: false,
            hide: (treatmentNotes?.length ?? 0) === 0 || !roles?.includes(ERoles.Practitioner)
        }
    ];

    const parseAndSetData = (data: any, callback: Dispatch<SetStateAction<any>>): void => {
        // For some reason empty arrays are coming through as strings
        if (!Array.isArray(data) && typeof data === 'string') {
            data = JSON.parse(data);
        }
        // let dataArray;
        //
        // data.map((item) => {
        //     dataArray.push(item);
        // });

        callback(data);
    };

    useEffect(() => {
        let unmounted = false;
        if (roles?.includes(ERoles.Practitioner)) {
            getPractitionerPsychologicalAttributes({
                patientId
            })
                .then((data) => validateResponse(data))
                .then((data) => !unmounted && parseAndSetData(data, setPsychologicalAttributes))
                .catch((e) => console.error(e.title));

            // get practitioner if user is a practioner (doctor)
            getPractitioner().then((data) => setPractitioner(data));
        }

        if (roles?.includes(ERoles.MakeupArtist) && !isPatientProfile) {
            getMakeup({ patientId })
                .then((data) => validateResponse(data))
                .then((data) => !unmounted && parseAndSetData(data, setMakeup))
                .catch((e) => console.error(e.title));
        }

        if (roles?.includes(ERoles.Barista) && !isPatientProfile) {
            getRefreshments({ patientId })
                .then((data) => validateResponse(data))
                .then((data) => !unmounted && parseAndSetData(data, setRefreshments))
                .catch((e) => console.error(e.title));
        }

        if (
            roles?.includes(ERoles.Nurse) ||
            roles?.includes(ERoles.Practitioner) ||
            roles?.includes(ERoles.Receptionist)
        ) {
            getTreatmentNotes({ patientId })
                .then((data) => validateResponse(data))
                .then((data) => !unmounted && parseAndSetData(data, setTreatmentNotes))
                .catch((e) => console.error(e.title));
        }

        if (
            roles?.includes(ERoles.SkincareConsultant) ||
            roles?.includes(ERoles.Nurse) ||
            roles?.includes(ERoles.Practitioner) ||
            roles?.includes(ERoles.Receptionist)
        ) {
            const appointmentId = appointment?.id;
            if (appointmentId) {
                getTreatmentAesthetician({ appointmentId, patientId })
                    .then(validateResponse)
                    .then((data) => !unmounted && parseAndSetData(data, setTreatmentAesthetician))
                    .catch((e) => console.error(e.error.message));
            }
        }

        getShareConsent({ patientId })
            .then((data) => validateResponse(data))
            .then((data) => {
                if (!unmounted) {
                    for (const element of data) {
                        for (const item of element) {
                            if (item.type === 'treatment_note') {
                                parseAndSetData(item, setShareConsent);
                                break;
                            }
                        }
                    }
                }
            })
            .catch((e) => console.error(e.title));

        !isPatientProfile &&
            getTreatmentPlan({ patientId })
                .then((data) => validateResponse(data))
                .then((data) => {
                    if (!unmounted) {
                        parseAndSetData(data?.plans, setTreatmentPlan);
                        data.downloadUrl;
                    }
                })
                .catch((e) => console.error(e.title));

        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <>
            {!!makeup?.makeup && <Styled.Extra>Last Makeup Request: {makeup?.makeup}</Styled.Extra>}
            {/*{!!refreshments?.refreshments && (*/}
            {/*  <Styled.Extra>*/}
            {/*    Last Refreshments Request:{" "}*/}
            {/*    {refreshments?.refreshments.split(",").join(", ")}*/}
            {/*  </Styled.Extra>*/}
            {/*)}*/}
            {isPatientProfile && patientProfile?.archetype && (
                <Styled.Archetype archetype={patientProfile.archetype} />
            )}
            <OxThemedInput theme={EInputTheme.BackgroundWhite}>
                <Styled.Links>
                    {links.map((item, index) => {
                        let linkProps = {};

                        if (item.to) {
                            linkProps = {
                                to: item.to,
                                target: item.newWindow ? '_blank' : '',
                                state: item.state
                            };
                        } else if (item.onClick) {
                            linkProps = {
                                onClick: item.onClick
                            };
                        }

                        return (
                            <React.Fragment key={index}>
                                {!item.hide && (
                                    <Styled.Link {...linkProps}>
                                        <OxButton>{item.title}</OxButton>
                                    </Styled.Link>
                                )}
                            </React.Fragment>
                        );
                    })}
                </Styled.Links>
            </OxThemedInput>
        </>
    );
};
