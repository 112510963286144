import { EApiEndpoint } from 'src/config/enums';
import {
    TAppointment,
    TClinic,
    TClinicAppointments,
    TError,
    TFeedback,
    TInstance,
    TPractitioner,
    TPractitionerGraphData,
    TPsychologicalAttributes
} from './api.types';
import { apiGetCall } from './api.core';
import { applyValues } from 'src/utils/applyValues';

export const getPractitioner = async (): Promise<TPractitioner | null> => {
    return await apiGetCall(EApiEndpoint.Practitioner, {
        credentials: true
    });
};

export const getPractitionerAppointment = async (
    from?: string,
    to?: string
): Promise<TClinicAppointments | null> => {
    return await apiGetCall(
        EApiEndpoint.PractitionerAppointment +
            (from ? `?from=${from}` : '') +
            (to ? `&to=${to}` : ''),
        {
            credentials: true
        }
    );
};

export const getPractitionerPsychologicalAttributes = async (
    values: {
        patientId: string;
    },
    all?: boolean
): Promise<TPsychologicalAttributes[] | null> => {
    return await apiGetCall(
        applyValues(EApiEndpoint.PractitionerPsychologicalAttributes, values) +
            (!all ? '?latest=1' : ''),
        {
            credentials: true
        }
    );
};

export const getPractitionerFirstConsultation = async (): Promise<
    | TError
    | {
          appointments: TAppointment[];
          clinic: TClinic;
      }[]
    | null
> => {
    return await apiGetCall(EApiEndpoint.PractitionerFirstConsultation, {
        credentials: true
    });
};

export const getPractitionerRevenue = async (values: {
    startDate: string;
    endDate: string;
}): Promise<TPractitionerGraphData | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.PractitionerRevenue, values), {
        credentials: true
    });
};

export const getPractitionerEarnings = async (values: {
    startDate: string;
    endDate: string;
}): Promise<TPractitionerGraphData | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.PractitionerEarnings, values), {
        credentials: true
    });
};

export const getPractitionerInvoices = async (values: {
    startDate: string;
    endDate: string;
}): Promise<TPractitionerGraphData | null> => {
    return await apiGetCall(applyValues(EApiEndpoint.PractitionerInvoices, values), {
        credentials: true
    });
};

export const getPractitionerInstance = async (): Promise<TInstance | null> => {
    return await apiGetCall(EApiEndpoint.PractitionerInstance, {
        credentials: true
    });
};

export const getPractitionerFeedback = async (): Promise<TFeedback | null> => {
    return await apiGetCall(EApiEndpoint.PractitionerFeedback, {
        credentials: true
    });
};
