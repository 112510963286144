import styled, { css } from 'styled-components';

import { fluidSizing } from 'src/utils';

export const Container = styled.div(
    ({ theme }) => css`
        display: flex;
        width: 100%;
        min-height: 100%;
        position: relative;
        ${theme.breakpoints.only('xs')} {
            flex-direction: column;
        }
    `
);

export const DashboardContent = styled.div<{ noPadding?: boolean }>(
    (noPadding) => css`
        display: flex;
        flex-direction: column;
        width: 100%;
        position: relative;
        ${!noPadding && fluidSizing([{ prop: 'padding-top', values: [20, 35, 35] }])}
        ${fluidSizing([{ prop: 'padding-left ', values: [0, 30, 20] }])}
    `
);
