import { AlertContext, EAlertVariant } from 'src/context/AlertContext';
import {
    EPsychologicalAttributesType,
    OxPsychologicalAttributes
} from 'src/panel/components/OxWelcomeIntenalFlow/components/OxPsychologicalAttributes';
import React, { useContext, useEffect, useState } from 'react';
import * as Styled from './OxWelcomeInternalFlow.styled';

import { OxPersonalityArchetypes } from 'src/components/OxPersonalityArchetypes';
import { OxMakeup } from 'src/panel/components/OxWelcomeIntenalFlow/components/OxMakeup';
import { OxSearchPatient } from 'src/panel/components/OxWelcomeIntenalFlow/components/OxSearchPatient';
import { OxWelcomeInternalContentWrapper } from 'src/panel/components/OxWelcomeIntenalFlow/components/OxWelcomeInternalContentWrapper';
import { TPatient, TPatientSearchResult, TUser } from 'src/services/api/api.types';
import { psychologicalCheckRespondent } from 'src/services/api/psychological';
import { navigate, WindowLocation } from '@reach/router';
import { ERoutes } from 'src/config/enums';
import { getSearchPatients } from 'src/services/api/reception';
import { OxSpinner } from 'src/components/OxSpinner';
import { useValidateResponse } from 'src/hooks/useValidateResponse';
import { OxPageHelmet } from 'src/components/OxPageHelmet';
import { OxStaffPin } from 'src/panel/components/OxStaffPin';
import { OxReferralSource } from 'src/panel/components/OxWelcomeIntenalFlow/components/OxReferralSource';
import { store } from 'src/panel/store';
import { OxGoalsSelect } from './components/OxGoalsSelect';

type TProps = {
    location?: WindowLocation & {
        state: {
            patientId: string;
        };
    };
};

export const OxWelcomeInternalFlow = ({ location }: TProps): JSX.Element => {
    const { validateResponse } = useValidateResponse();
    const statePatientId = location?.state?.patientId;
    const [loading, setLoading] = useState<boolean>(false);
    const [patient, setPatient] = useState<TPatientSearchResult | null>();
    const [clinicId, setClinicId] = useState<number | null>();
    const [step, setStep] = useState(statePatientId ? 1 : 0);
    const { showAlert } = useContext(AlertContext);
    const { user }: { user?: TUser } = store.getState();
    const [treatmentType, setTreatmentType] = useState<string>();

    const onSelect = (patient: TPatientSearchResult) => {
        setPatient(patient);
        setStep((prev) => prev + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const handleLoading = (value: boolean) => {
        setLoading(value);
    };

    const handleTreatmentType = (value: string) => {
        setTreatmentType(value);
    };

    const onNexStep = async (): Promise<void> => {
        //handle longevity treatment to skip gloals and fears
        if (step === 1 && treatmentType === 'Longevity Treatment') {
            try {
                const checkRespondent = await psychologicalCheckRespondent(
                    {
                        surveyId: 1
                    },
                    {
                        respondent: {
                            email: patient?.email
                        }
                    }
                );
                if (checkRespondent && checkRespondent.exists) {
                    setStep((prev) => prev + 4);
                } else {
                    setStep((prev) => prev + 3);
                }
                window.scrollTo({ top: 0, behavior: 'smooth' });
                return;
            } catch (err) {
                showAlert({
                    type: EAlertVariant.Error,
                    header: 'Error',
                    title: 'Something went wrong'
                });
            }
        }

        if (step === 3) {
            try {
                const checkRespondent = await psychologicalCheckRespondent(
                    {
                        surveyId: 1
                    },
                    {
                        respondent: {
                            email: patient?.email
                        }
                    }
                );
                if (checkRespondent && checkRespondent.exists) {
                    setStep((prev) => prev + 2);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    return;
                }
            } catch (err) {
                showAlert({
                    type: EAlertVariant.Error,
                    header: 'Error',
                    title: 'Something went wrong'
                });
            }
        }
        setStep((prev) => prev + 1);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const getPatientFullName = (): string =>
        `${patient?.title !== null ? patient?.title : ''} ${patient?.firstname} ${
            patient?.lastname
        }`;

    const onBack = () => {
        //handle longevity treatment to skip gloals and fears
        if (step === 4 && treatmentType === 'Longevity Treatment') {
            setStep(1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else if (step === 1) {
            if (!statePatientId) {
                setPatient(null);
                setStep((prev) => prev - 1);
            } else {
                navigate(-1);
            }
            // } else if (step === 4) {
            //     navigate(ERoutes.PanelStaffLanding);
        } else {
            setStep((prev) => prev - 1);
        }
    };

    useEffect(() => {
        let unmounted = false;

        setClinicId(user.clinicId);

        if (statePatientId) {
            setLoading(true);

            getSearchPatients({ clinicId: user.clinicId }, { patientId: statePatientId })
                .then((data) => validateResponse(data))
                .then((data) => !unmounted && data.length === 1 && setPatient(data[0]))
                .catch((e) => (console.dir(e), console.error(e.title)))
                .finally(() => !unmounted && setLoading(false));
        }

        return (): void => {
            unmounted = true;
        };
    }, []);

    return (
        <>
            <OxPageHelmet title="Welcome Flow" />
            {loading && (
                <OxSpinner
                    addTranslate
                    style={{
                        left: '50%',
                        top: '50%',
                        position: 'absolute',
                        opacity: 0.8
                    }}
                />
            )}
            {step === 0 && <OxSearchPatient onSelect={onSelect} centerVertically={true} />}
            {patient && (
                <>
                    {step !== 4 && (
                        <OxWelcomeInternalContentWrapper
                            header={getPatientFullName()}
                            onBack={onBack}
                        >
                            {step === 1 && (
                                <OxGoalsSelect
                                    treatmentType={treatmentType}
                                    treatmentTypeChange={handleTreatmentType}
                                    onNext={onNexStep}
                                />
                            )}
                            {step === 2 && (
                                <OxPsychologicalAttributes
                                    patient={
                                        {
                                            firstName: patient.firstname,
                                            lastName: patient.lastname,
                                            email: patient.email,
                                            patientPhoneNumbers: [{ number: patient.mobile }],
                                            gender: patient.gender,
                                            newsletter: patient.newsletter
                                        } as TPatient
                                    }
                                    handleLoadingChange={handleLoading}
                                    loading={loading}
                                    onNext={onNexStep}
                                    type={
                                        treatmentType === 'Hair Treatment'
                                            ? EPsychologicalAttributesType.hairGoals
                                            : EPsychologicalAttributesType.socialGoals
                                    }
                                />
                            )}
                            {step === 3 && (
                                <OxPsychologicalAttributes
                                    patient={
                                        {
                                            firstName: patient.firstname,
                                            lastName: patient.lastname,
                                            email: patient.email,
                                            patientPhoneNumbers: [{ number: patient.mobile }],
                                            gender: patient.gender,
                                            newsletter: patient.newsletter
                                        } as TPatient
                                    }
                                    handleLoadingChange={handleLoading}
                                    loading={loading}
                                    onNext={onNexStep}
                                    type={
                                        treatmentType === 'Hair Treatment'
                                            ? EPsychologicalAttributesType.hairFears
                                            : EPsychologicalAttributesType.personalFears
                                    }
                                />
                            )}
                            {step === 5 && (
                                <OxStaffPin
                                    description="Thank you for filling in this form, please hand the device back to the advisor."
                                    successCallback={onNexStep}
                                />
                            )}
                            {step === 6 && (
                                <OxReferralSource
                                    patientId={patient.patients?.[clinicId]?.patientId || ''}
                                    clinicId={clinicId}
                                    onNext={onNexStep}
                                />
                            )}
                            {step === 7 && (
                                <OxMakeup
                                    patientId={patient.patients?.[clinicId]?.patientId || ''}
                                    clinicId={clinicId}
                                    onNext={() => navigate(ERoutes.PanelStaffLanding)}
                                />
                            )}
                        </OxWelcomeInternalContentWrapper>
                    )}
                    {step === 4 && (
                        <Styled.ArchetypeContainer>
                            <OxPersonalityArchetypes
                                personalityData={{
                                    firstName: patient.firstname || '',
                                    lastName: patient.lastname || '',
                                    email: patient.email || '',
                                    mobile: patient.mobile || '',
                                    gender: patient.gender || '',
                                    newsletter: patient.newsletter || false
                                }}
                                onNext={onNexStep}
                                reception
                                header={getPatientFullName()}
                                onBack={onBack}
                            />
                        </Styled.ArchetypeContainer>
                    )}
                </>
            )}
        </>
    );
};
